import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FaFacebook, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { strapiApi } from '../../api';
import SEOHead from '../../components/SEOHead';

const BlogPost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        const response = await strapiApi.get(
          `/api/blog-posts?filters[slug][$eq]=${slug}&populate=*`
        );
        
        if (response.data?.data?.[0]?.attributes) {
          setPost(response.data.data[0].attributes);
        } else {
          setError('Blog post not found');
        }
      } catch (err) {
        console.error('Error fetching blog post:', err);
        setError('Failed to fetch blog post. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPost();
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    document.body.style.scrollBehavior = 'auto';
    return () => {
      document.body.style.scrollBehavior = 'smooth';
    };
  }, [slug]);

  // Helper functions remain the same
  const getPlainTextContent = (content) => {
    if (!content || !Array.isArray(content)) return '';
    return content.map(block => {
      if (block.children) {
        return block.children.map(child => child.text || '').join(' ');
      }
      return '';
    }).join(' ').trim();
  };

  const renderBlock = (block, index) => {
    if (!block) return null;

    switch (block.type) {
      case 'paragraph':
        return block.children?.length > 0 && (
          <p key={index} className="mb-6 leading-relaxed whitespace-pre-wrap text-left">
            {block.children.map((child, childIndex) => (
              <span key={childIndex}>{child.text}</span>
            ))}
          </p>
        );
      case 'heading':
        const headingClasses = {
          2: "text-3xl border-b-2 border-primary-yellow pb-2.5 mb-2",
          3: "text-2xl mb-2",
          4: "text-xl mb-2"
        };
        const HeadingTag = `h${block.level || 2}`;
        return block.children?.length > 0 && (
          <HeadingTag 
            key={index} 
            className={`text-primary-red font-bold mt-6 mb-2 ${headingClasses[block.level || 2]}`}
          >
            {block.children.map((child, childIndex) => (
              <span key={childIndex}>{child.text}</span>
            ))}
          </HeadingTag>
        );
      case 'list':
        const ListTag = block.style === 'ordered' ? 'ol' : 'ul';
        return (
          <ListTag 
            key={index} 
            className={`mb-6 ml-6 ${block.style === 'ordered' ? 'list-decimal' : 'list-disc'}`}
          >
            {block.children?.map((item, itemIndex) => (
              <li key={itemIndex} className="mb-3 pl-2 leading-relaxed">
                {item.children?.map((child, childIndex) => (
                  <span key={childIndex}>{child.text}</span>
                ))}
              </li>
            ))}
          </ListTag>
        );
      case 'quote':
        return (
          <blockquote 
            key={index} 
            className="border-l-4 border-primary-red my-6 pl-4 italic"
          >
            {block.children?.map((child, childIndex) => (
              <p key={childIndex} className="mb-3">{child.text}</p>
            ))}
          </blockquote>
        );
      default:
        return null;
    }
  };

  const renderContent = (content) => {
    if (!content) return <p>No content available</p>;
    if (!Array.isArray(content)) return <p>{String(content)}</p>;
    return content.map((block, index) => renderBlock(block, index));
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-white">
        <div className="bg-gradient-to-br from-primary-red to-[#8B0000] py-16">
          <div className="max-w-3xl mx-auto px-6">
            <div className="text-center text-primary-white text-xl py-16">Loading post...</div>
          </div>
        </div>
      </div>
    );
  }

  if (error || !post) {
    return (
      <div className="min-h-screen bg-white">
        <div className="bg-gradient-to-br from-primary-red to-[#8B0000] py-16">
          <div className="max-w-3xl mx-auto px-6">
            <div className="bg-white rounded-xl p-8 text-center">
              <p className="text-primary-red mb-4">{error || 'Post not found'}</p>
              <Link to="/blog" className="text-primary-red font-bold hover:text-primary-yellow transition-colors duration-300">
                &larr; Back to Blog
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const shareUrl = window.location.href;
  const plainTextContent = getPlainTextContent(post.content);
  const description = post.excerpt || plainTextContent.substring(0, 160);

  return (
    <div className="relative flex flex-col min-h-screen font-main" id="main-content">
      <SEOHead 
        title={`${post.title} | World Budo Arts Blog`}
        description={description}
        path={`/blog/${slug}`}
      />

      <div className="relative w-full py-24 bg-primary-black bg-gradient-to-b from-black/80 to-primary-red/60 text-primary-white m-0">
        <div className="relative z-10 max-w-3xl mx-auto px-5 text-center">
          <h1 className="text-[2.5rem] text-primary-yellow font-display shadow-text">
            {post.title}
          </h1>
          <p className="text-lg text-primary-white/90">
            By Shihan Tom | {new Date(post.createdAt).toLocaleDateString()}
          </p>
        </div>
      </div>

      <div className="bg-gradient-to-br from-primary-red to-[#8B0000] py-16 flex-grow m-0">
        <div className="bg-white rounded-xl max-w-3xl mx-auto px-6 md:px-10 py-10 shadow-xl">
          <Link 
            to="/blog" 
            className="inline-flex items-center text-primary-red font-bold mb-8 hover:text-primary-yellow 
                     transition-all duration-300 hover:-translate-x-1"
          >
            &larr; Back to Blog
          </Link>

          <article className="prose max-w-none text-lg text-gray-800">
            {post.image?.data && (
              <img 
                src={`${process.env.REACT_APP_STRAPI_URL}${post.image.data.attributes.url}`}
                alt={post.title}
                className="w-full max-h-[500px] object-cover rounded-lg shadow-md mb-8"
              />
            )}
            {renderContent(post.content)}
            
            <div className="mt-16 bg-gray-50 p-8 rounded-lg border border-gray-200">
              <h2 className="text-2xl text-primary-red font-bold mb-6 text-center">Share this article</h2>
              <div className="flex flex-col md:flex-row gap-4 justify-center">
                <button 
                  onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, '_blank')}
                  className="flex items-center justify-center gap-2 min-w-[160px] px-6 py-3 bg-[#1877f2] 
                           text-white rounded-lg font-semibold hover:-translate-y-0.5 hover:shadow-lg 
                           transition-all duration-300"
                >
                  <FaFacebook /> Facebook
                </button>
                <button 
                  onClick={() => window.open(`https://twitter.com/intent/tweet?url=${shareUrl}&text=${encodeURIComponent(post.title)}`, '_blank')}
                  className="flex items-center justify-center gap-2 min-w-[160px] px-6 py-3 bg-black 
                           text-white rounded-lg font-semibold hover:-translate-y-0.5 hover:shadow-lg 
                           transition-all duration-300"
                >
                  <FaXTwitter /> X
                </button>
                <button 
                  onClick={() => window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${encodeURIComponent(post.title)}`, '_blank')}
                  className="flex items-center justify-center gap-2 min-w-[160px] px-6 py-3 bg-[#0a66c2] 
                           text-white rounded-lg font-semibold hover:-translate-y-0.5 hover:shadow-lg 
                           transition-all duration-300"
                >
                  <FaLinkedin /> LinkedIn
                </button>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;