import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { strapiApi } from "../../api";
import NewsletterPopup from "../../components/NewsletterPopup/NewsletterPopup";
import SignupModal from '../../components/SignUpModal/SignUpModal';
import SEOHead from "../../components/SEOHead";

const HomePage = () => {
  const scrollRef = useRef(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isVideoHovered, setIsVideoHovered] = useState(false);
  const videoRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const [events, setEvents] = useState([]);
  const [isPhilosophyVisible, setIsPhilosophyVisible] = useState(true);
  const [isAppPromoVisible, setIsAppPromoVisible] = useState(true);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);

  const lessons = [
    {
      name: "Technique",
      description:
        "Study fundamental martial arts techniques and refine your form.",
    },
    {
      name: "Kata",
      description:
        "Learn and perfect traditional kata sequences to enhance your skills.",
    },
    {
      name: "Self-Defense",
      description:
        "Develop practical self-defense skills for real-world situations.",
    },
    {
      name: "Iken",
      description:
        "Explore the philosophical aspects of martial arts and their application in daily life.",
    },
    {
      name: "Conditioning",
      description:
        "Improve your physical fitness and stamina with martial arts-specific exercises.",
    },
    {
      name: "Speed",
      description:
        "Enhance your reaction time and movement speed for quick, effective techniques.",
    },
    {
      name: "Flexibility",
      description:
        "Increase your range of motion and prevent injuries through targeted stretching routines.",
    },
  ];

  const testimonials = [
    {
      text: "Shihan Tom Callahan is a true Martial Artist. I've enjoyed training with him and his many talented students over the years. I'm proud to call him a friend.",
      author: "Dolph Lundgren",
      description: "Actor, Director, Martial Artist - 4th Dan Black Belt",
      image: "/images/dolph-lundgren.jpg",
    },
    {
      text: "Sensei Callahan is an internationally respected Martial Artist and Instructor. The Machida's and Sensei Callahan share the same Budo philosophy. OSU!",
      author: "Chinzo Machida",
      description: "Professional MMA Fighter, Karate Master",
      image: "/images/chinzo-machida.jpg",
    },
    {
      text: "Sensei Tom's approach to wellness combines both the East and West philosophies. He helped me not only get in great shape, but I have increased energy and mental clarity, which has had a positive impact on my daily work life.",
      author: "Stefano Curti",
      description: "Chief Brand Officer of Coty",
      image: "/images/stefano-curti.jpg",
    },
    {
      text: "Shihan Tom embodies the true spirit of Kyokushin, offering authentic and transformative training that empowers students to reach new heights. His seminars are a testament to the power of dedication and passion, inspiring a new generation of practitioners to embrace the art's full potential.",
      author: "Daisy Lang",
      description: "Three Division Boxing World Champion",
      image: "/images/daisy-lang.jpg",
    },
    {
      text: "I call Shihan Tom my older brother as well because he is someone I look up and aspire to be. He represents what the budo and Kyokushin spirit is about. Train hard, never give up, being a good human being and thinking of others.",
      author: "Judd Reid",
      description: "Completed 100-Man Kyokushin Kumite",
      image: "/images/judd-reid.jpg",
    },
  ];

  const handleOpenSignupModal = () => {
    setIsSignupModalOpen(true);
  };

  const handleCloseSignupModal = () => {
    setIsSignupModalOpen(false);
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const playVideo = () => {
        video.play().catch((error) => {
          console.log("Auto-play was prevented:", error);
        });
      };

      video.addEventListener('loadeddata', () => {
        setIsVideoLoaded(true);
        playVideo();
      });

      // Try to play on any user interaction
      const handleInteraction = () => {
        playVideo();
        document.removeEventListener('click', handleInteraction);
        document.removeEventListener('touchstart', handleInteraction);
      };

      document.addEventListener('click', handleInteraction);
      document.addEventListener('touchstart', handleInteraction);

      return () => {
        document.removeEventListener('click', handleInteraction);
        document.removeEventListener('touchstart', handleInteraction);
      };
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Updated scroll handler
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const philosophySection = document.querySelector('[data-section="philosophy"]');
      const appPromoSection = document.querySelector('[data-section="app-promo"]');

      if (philosophySection) {
        const philosophySectionTop = philosophySection.getBoundingClientRect().top;
        if (philosophySectionTop < windowHeight * 0.75) {
          setIsPhilosophyVisible(true);
        } else {
          setIsPhilosophyVisible(false);
        }
      }

      if (appPromoSection) {
        const appPromoSectionTop = appPromoSection.getBoundingClientRect().top;
        if (appPromoSectionTop < windowHeight * 0.75) {
          setIsAppPromoVisible(true);
        } else {
          setIsAppPromoVisible(false);
        }
      }
    };

    // Initial scroll check
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    // Keep all your existing scroll container logic
    const scrollContainer = scrollRef.current;

    const updateScrollWidth = () => {
      if (scrollContainer) {
        setScrollWidth(scrollContainer.scrollWidth / 2);
      }
    };

    updateScrollWidth();
    window.addEventListener("resize", updateScrollWidth);

    let animationId;
    const scrollLeftToRight = () => {
      if (!isHovering && scrollContainer) {
        scrollContainer.scrollLeft += 1;
        if (scrollContainer.scrollLeft >= scrollWidth) {
          scrollContainer.scrollLeft = 0;
        }
      }
      animationId = requestAnimationFrame(scrollLeftToRight);
    };

    animationId = requestAnimationFrame(scrollLeftToRight);

    const testimonialTimer = setInterval(() => {
      setIsSliding(true);
      setTimeout(() => {
        setCurrentTestimonial(
          (prev) => (prev + 1) % testimonials.length
        );
        setIsSliding(false);
      }, 500);
    }, 7000);

    // Update cleanup to include new scroll listener
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);  // Add this line
      window.removeEventListener("resize", updateScrollWidth);
      cancelAnimationFrame(animationId);
      clearInterval(testimonialTimer);
    };
  }, [isHovering, scrollWidth, testimonials.length]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await strapiApi.get("/api/events?populate=*");

        if (response.data && response.data.data) {
          // Get upcoming events
          const now = new Date();
          now.setHours(0, 0, 0, 0);

          const upcomingEvents = response.data.data
            .filter(event => {
              const eventDate = new Date(event.attributes.date);
              eventDate.setHours(0, 0, 0, 0);
              return eventDate >= now;
            })
            .sort((a, b) => {
              const dateA = new Date(a.attributes.date);
              const dateB = new Date(b.attributes.date);
              return dateA - dateB;
            })
            .slice(0, 3); // Only take first 3 upcoming events

          setEvents(upcomingEvents);
        } else {
          console.error("Unexpected events data structure:", response.data);
        }
      } catch (err) {
        console.error("Error fetching events:", err);
      }
    };

    fetchEvents();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div className="min-h-screen" id="main-content">
      <SEOHead
        title="World Budo Arts - Study the Way of Budo | Martial Arts Training"
        description="Begin your martial arts journey with World Budo Arts. Learn authentic Kyokushin Karate from Shihan Tom Callahan anytime, anywhere. Join our global community of martial artists."
        path="/"
      />

      {/* Hero Section */}
      <section className="relative h-screen flex flex-col items-center justify-center text-white overflow-hidden bg-black">
        {/* Background Video */}
        <video
          ref={videoRef}
          autoPlay
          loop
          muted
          playsInline
          className={`absolute top-0 left-0 w-full h-full object-cover z-0 transition-opacity duration-500 
      ${isVideoLoaded ? "opacity-100" : "opacity-0"}`}
          poster="/videos/hero-poster.jpg"
        >
          <source src="/videos/hero-background.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Main Hero Content */}
        <div className="relative bg-black/80 p-8 rounded-lg z-10 w-11/12 md:w-auto text-center">
          <h1 className="text-[2.5rem] md:text-[3.5rem] mb-4 text-primary-red font-display text-shadow shadow-black">
            Study the Way of Budo
          </h1>
          <p className="text-xl md:text-2xl mb-8 font-main text-shadow shadow-black">
            Embark on your path to excellence with{" "}
            <Link to="/About" className="no-underline hover:no-underline">
              <span className="text-primary-yellow hover:text-primary-red transition-colors duration-400">
                Shihan Tom
              </span>
            </Link>{" "}
            and our diverse community of martial artists.
          </p>
          <button
            onClick={handleOpenSignupModal}
            className="inline-block px-6 py-3 bg-primary-red text-white font-bold rounded-lg 
                hover:bg-primary-yellow hover:text-black transition-all duration-300 
                transform hover:scale-105 hover:shadow-lg"
          >
            Free Lesson
          </button>
        </div>


        {/* Logo Section */}
        <div className="absolute bottom-0 left-0 right-0 py-6 z-10">
          <div className="container mx-auto flex justify-center items-center gap-8">
            <img
              src="/logo.png"
              alt="World Budo Arts Logo"
              className="w-28 h-28 object-contain"
            />
            <img
              src="/images/kyokushin-kan-logo.png"
              alt="Kyokushin Kan Logo"
              className="w-28 h-28 object-contain"
            />
          </div>
        </div>
      </section>

      <section
        data-section="philosophy"
        className={`relative bg-[#f9f9f9] py-20 transition-all duration-1000 ease-in-out
          ${isPhilosophyVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
      >
        <div className="container mx-auto px-4 max-w-7xl">
          <div className="flex flex-col md:flex-row items-center md:items-start gap-10 text-center md:text-left">
            <div className="flex-1 w-full md:w-auto">
              <h2 className="font-display text-primary-red text-[2rem] md:text-[2.5rem] mb-8">
                Our Philosophy
              </h2>
              <p className="text-base md:text-[1.2rem] leading-relaxed mb-8">
                World Budo Arts was created by Shihan Tom Callahan as not only a dojo, but a brand and
                idea that encapsulates the ethos of a training philosophy. We believe in the power of
                martial arts to transform lives, build character, and foster a sense of community.
              </p>
              <Link
                to="/about"
                className="inline-block max-w-[250px] text-center px-6 py-3 bg-primary-red text-white 
                     font-bold rounded-lg hover:bg-primary-yellow hover:text-black transition-all 
                     duration-400 hover:scale-110 hover:shadow-lg hover:no-underline"
              >
                Learn More About Us
              </Link>
            </div>
            <div className="flex-1 w-full md:max-w-[50%] mt-8 md:mt-0">
              <div
                className={`relative rounded-lg overflow-hidden transition-all duration-300 
                  ${isVideoHovered ? 'transform scale-102 shadow-[0_0_0_3px] shadow-primary-red' : ''}`}
                onMouseEnter={() => setIsVideoHovered(true)}
                onMouseLeave={() => setIsVideoHovered(false)}
              >
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/5YFWgZwywfk"
                  title="World Budo Arts Philosophy"
                  frameBorder="0"
                  loading="lazy"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="rounded-lg"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* App Promo Section */}
      <section
        data-section="app-promo"
        className="relative py-20 bg-[#f9f9f9] overflow-hidden"
      >
        <div className="container mx-auto px-4 max-w-7xl">
          <div className={`flex flex-col md:flex-row items-center gap-[30px] md:gap-[60px] transition-all duration-1000 ease-in-out
            ${isAppPromoVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          >
            <div className="w-full max-w-[200px] md:max-w-[250px] mb-8 md:mb-0">
              <img
                src="/images/app-mockup.png"
                alt="World Budo Arts Mobile App"
                className="w-full h-auto object-contain"
              />
            </div>
            <div className="flex-1 text-center md:text-left">
              <h2 className="font-display text-primary-red text-[2rem] md:text-[2.5rem]">
                Join Our Waitlist
              </h2>
              <h3 className="font-display text-primary-red text-[2rem] md:text-[2.3rem] mb-5">
                Get Early Access to Our App
              </h3>
              <p className="text-base md:text-[1.1rem] leading-relaxed mb-5">
                <span className="text-primary-yellow font-semibold">
                  Join our exclusive waitlist and receive a free martial arts lesson from Shihan Tom.
                </span>{" "}
                Be among the first to experience our revolutionary training platform featuring:
              </p>
              <ul className="list-none pl-0 mb-8">
                {[
                  'Live streaming training sessions with Shihan Tom',
                  'Step-by-step technique breakdowns and tutorials',
                  'Interactive training programs tailored to your level',
                  'Access to exclusive seminar content and special events',
                  'Progress tracking with personalized feedback',
                  'Connect with our global martial arts community',
                  'Mobile-optimized for training anywhere, anytime'
                ].map((feature, index) => (
                  <li
                    key={index}
                    className="text-base leading-[1.8] mb-[10px] pl-0 md:pl-8 relative 
           before:content-['✓'] before:absolute before:left-0 
           before:text-primary-yellow before:font-bold 
           md:before:static md:before:mr-2.5"
                  >
                    {feature}
                  </li>
                ))}
              </ul>
              <button
                onClick={handleOpenSignupModal}
                className="inline-block px-6 py-3 bg-primary-red text-white font-bold rounded-lg 
      hover:bg-primary-yellow hover:text-black transition-all duration-300 
      hover:scale-105 hover:shadow-lg"
              >
                Join Waitlist
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="py-20 overflow-hidden">
        <div className="container mx-auto px-4 max-w-7xl">
          <h2 className="font-display text-center text-primary-red text-[2rem] md:text-[2.5rem] mb-10">
            Our Lessons
          </h2>
          <div
            ref={scrollRef}
            className="overflow-x-auto whitespace-nowrap py-5 scrollbar-none touch-pan-x"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            <div className="inline-flex pb-5">
              {lessons.concat(lessons).map((lesson, index) => (
                <div
                  key={index}
                  className="flex-none w-[180px] md:w-[200px] flex flex-col h-[180px] md:h-[200px] 
                     mr-4 md:mr-5 p-3 md:p-4 bg-[#f5f5f5] rounded-lg text-center shadow-md
                     transition-all duration-300 ease-in-out hover:transform hover:-translate-y-[5px] 
                     hover:shadow-lg hover:border-2 hover:border-primary-red"
                >
                  <h3 className="font-display text-base md:text-[1.2rem] mb-2 text-primary-red">
                    {lesson.name}
                  </h3>
                  <p className="text-[0.8rem] md:text-[0.85rem] leading-[1.4] mb-4 flex-grow 
                       overflow-hidden line-clamp-4 whitespace-normal">
                    {lesson.description}
                  </p>
                  <button
                    onClick={handleOpenSignupModal}
                    className="self-center mt-auto px-4 py-2 text-[0.8rem] md:text-[0.85rem] 
    bg-primary-yellow text-black font-bold rounded-lg hover:bg-primary-red 
    hover:text-white transition-all duration-300"
                  >
                    Free Lesson
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="bg-primary-yellow py-[60px]">
        <div className="container mx-auto px-4 max-w-7xl">
          <h2 className="font-display text-center text-primary-red text-[2rem] md:text-[2.5rem] mb-8">
            Testimonials
          </h2>
          <div className="flex flex-col md:flex-row justify-between items-center md:items-stretch mb-8 
              overflow-hidden min-h-[400px] md:min-h-[300px]">
            <div
              className={`flex-1 px-4 md:pr-8 flex flex-col justify-center text-center md:text-left
                transition-transform duration-500 ease-in-out mb-8 md:mb-0
                ${isSliding ? 'translate-x-[-100%]' : 'translate-x-0'}`}
            >
              <p className="text-base md:text-[1.2rem] leading-relaxed mb-4 overflow-y-auto 
                  max-h-[200px] pr-2.5">
                {testimonials[currentTestimonial].text}
              </p>
              <div className="mt-1.5">
                <p className="font-bold text-primary-red leading-snug">
                  - {testimonials[currentTestimonial].author}
                </p>
                <p className="text-[0.8rem] md:text-[0.9rem] text-primary-black italic leading-snug">
                  {testimonials[currentTestimonial].description}
                </p>
              </div>
            </div>

            <div
              className={`flex-1 flex items-center justify-center transition-transform duration-500 ease-in-out
                ${isSliding ? 'translate-x-[100%]' : 'translate-x-0'}`}
            >
              <img
                src={testimonials[currentTestimonial].image}
                alt={testimonials[currentTestimonial].author}
                className="w-[200px] h-[200px] md:w-[300px] md:h-[300px] object-cover rounded-lg 
                    shadow-md transition-transform duration-300 hover:scale-105"
              />
            </div>
          </div>

          <div className="flex justify-center gap-2.5">
            {testimonials.map((_, index) => (
              <button
                key={index}
                className={`w-2.5 h-2.5 rounded-full border-none p-0 transition-opacity duration-300
                  bg-primary-black ${index === currentTestimonial ? 'opacity-100' : 'opacity-30'}
                  hover:opacity-100 cursor-pointer`}
                onClick={() => setCurrentTestimonial(index)}
                aria-label={`Go to testimonial ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </section>

      {/* Events Section */}
      <section className="py-[60px] bg-[#f9f9f9]">
        <div className="container mx-auto px-4 max-w-7xl">
          <h2 className="font-display text-center text-primary-red text-[2rem] md:text-[2.5rem] mb-10">
            Upcoming Events
          </h2>
          <div className="w-full">
            {events && events.length === 0 ? (
              <div className="bg-white rounded-lg p-6 md:p-10 text-center shadow-md max-w-[800px] mx-auto">
                <h3 className="text-primary-red text-[1.5rem] md:text-[1.8rem] mb-5">
                  Exciting Events Coming Soon!
                </h3>
                <p className="text-base md:text-[1.1rem] leading-relaxed mb-4">
                  We're currently planning our next series of martial arts events and seminars.
                </p>
                <p className="text-base md:text-[1.1rem] leading-relaxed mb-8">
                  Stay tuned for upcoming opportunities to train, learn, and grow with our community.
                </p>
                <div className="flex flex-col sm:flex-row justify-center gap-4 sm:gap-5">
                  <Link
                    to="/contact"
                    className="inline-block px-6 py-3 bg-primary-red text-white font-bold rounded-lg 
                      hover:bg-primary-yellow hover:text-black transition-all duration-300 
                      hover:scale-105 hover:no-underline w-full sm:w-auto"
                  >
                    Get Notified
                  </Link>
                  <Link
                    to="/blog"
                    className="inline-block px-6 py-3 bg-primary-red text-white font-bold rounded-lg 
                      hover:bg-primary-yellow hover:text-black transition-all duration-300 
                      hover:scale-105 hover:no-underline w-full sm:w-auto"
                  >
                    Read Our Blog
                  </Link>
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 p-4 md:p-5">
                {events.map((event) => {
                  const eventDate = new Date(event.attributes.date);
                  const now = new Date();
                  const timeDiff = eventDate.getTime() - now.getTime();
                  const daysUntil = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

                  let description = "No description available";
                  if (event.attributes.description) {
                    if (Array.isArray(event.attributes.description)) {
                      description = event.attributes.description[0]?.children[0]?.text || description;
                    } else if (typeof event.attributes.description === 'string') {
                      description = event.attributes.description;
                    }
                  }
                  const truncatedDescription = description.length > 100
                    ? `${description.substring(0, 100)}...`
                    : description;

                  return (
                    <div
                      key={`event-${event.id}`}
                      className="relative bg-white rounded-[15px] overflow-hidden shadow-lg 
                        border border-gray-50 transition-all duration-300
                        hover:transform hover:-translate-y-1.5 hover:shadow-xl"
                    >
                      <div className={`absolute top-4 md:top-5 right-4 md:right-5 py-1.5 md:py-2 
                        px-3 md:px-4 rounded-full text-sm font-semibold
                        ${daysUntil < 0 ? 'bg-gray-500 text-white' : 'bg-primary-yellow text-black'}
                        shadow-md z-10`}
                      >
                        {daysUntil < 0
                          ? `${Math.abs(daysUntil)} days ago`
                          : daysUntil === 0
                            ? "Today!"
                            : daysUntil === 1
                              ? "Tomorrow!"
                              : `${daysUntil} days away`}
                      </div>

                      <div className="py-12 md:py-16 px-6 md:px-8">
                        <h3 className="font-display text-primary-red text-[1.5rem] md:text-[1.8rem] 
                          leading-tight min-h-[60px] md:min-h-[80px]">
                          {event.attributes.name}
                        </h3>

                        <div className="my-4 md:my-6 py-4 border-y border-gray-100">
                          <p className="mb-3">
                            <span className="font-semibold text-primary-red uppercase text-sm tracking-wider mr-2.5">
                              Date:
                            </span>
                            {eventDate.toLocaleDateString('en-US', {
                              weekday: 'long',
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric'
                            })}
                          </p>
                          <p>
                            <span className="font-semibold text-primary-red uppercase text-sm tracking-wider mr-2.5">
                              Location:
                            </span>
                            {event.attributes.location}
                          </p>
                        </div>

                        <p className="text-gray-600 leading-relaxed min-h-[60px] md:min-h-[80px] text-sm md:text-base">
                          {truncatedDescription}
                        </p>

                        <div className="text-center mt-6">
                          <Link
                            to="/events"
                            className="inline-block px-6 py-3 bg-primary-red text-white font-bold rounded-lg 
                              hover:bg-primary-yellow hover:text-black transition-all duration-300 
                              hover:transform hover:-translate-y-0.5 hover:no-underline
                              w-full sm:w-auto"
                          >
                            Learn More
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-primary-red text-white py-[40px] md:py-[60px] text-center">
        <div className="container mx-auto px-4 max-w-7xl">
          <h2 className="font-display text-[2rem] md:text-[2.5rem] mb-4 md:mb-5">
            Ready to Begin Your Journey?
          </h2>
          <p className="text-base md:text-[1.2rem] mb-6 md:mb-8 max-w-[600px] mx-auto">
            Join World Budo Arts today and discover the transformative power of martial arts.
          </p>
          <Link
            to="/contact"
            className="inline-block px-6 py-3 bg-primary-yellow text-black font-bold rounded-lg
              hover:bg-white hover:text-primary-red transition-all duration-300
              transform hover:scale-105 hover:shadow-lg hover:no-underline
              w-full sm:w-auto max-w-[300px]"
          >
            Contact Us
          </Link>
        </div>
      </section>
      <SignupModal
        isOpen={isSignupModalOpen}
        onClose={handleCloseSignupModal}
      />
      <NewsletterPopup />
    </div>
  );
};

export default HomePage;
