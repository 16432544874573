import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchBlogPosts } from '../../api';
import SEOHead from '../../components/SEOHead';

const BlogPage = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadBlogPosts = async () => {
      try {
        const response = await fetchBlogPosts();
        if (response && response.data) {
          setBlogPosts(response.data);
        } else {
          setError('No blog posts found');
        }
        setLoading(false);
      } catch (err) {
        console.error('Error loading blog posts:', err);
        setError(err.message || 'Failed to fetch blog posts');
        setLoading(false);
      }
    };

    loadBlogPosts();
  }, []);

  const getImageUrl = (post) => {
    if (post.attributes.image?.data?.attributes?.url) {
      return `${process.env.REACT_APP_STRAPI_URL}${post.attributes.image.data.attributes.url}`;
    }
    return '/images/blog-default.jpg'; // Fallback image
  };

  if (loading) return (
    <div className="w-full font-main">
      <div className="max-w-7xl mx-auto px-5">
        <div className="text-center py-10">Loading blog posts...</div>
      </div>
    </div>
  );

  if (error) return (
    <div className="w-full font-main">
      <div className="max-w-7xl mx-auto px-5">
        <div className="text-center py-10 bg-white rounded-lg shadow-md mx-auto max-w-2xl mt-16">
          <h2 className="text-primary-red text-2xl font-bold mb-4">Error Loading Blog Posts</h2>
          <p className="text-gray-700">{error}</p>
          {error.includes('Authentication') && (
            <p className="mt-4 text-gray-600">Please contact the administrator to resolve this issue.</p>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="relative flex flex-col min-h-screen font-main" id="main-content">
      <SEOHead 
        title="Martial Arts Blog | World Budo Arts"
        description="Explore martial arts techniques, philosophy, and training insights from Shihan Tom Callahan and the World Budo Arts community."
        path="/blog"
      />
      
      {/* Intro Section - Matching About Page styling */}
      <section className="relative w-full py-20 bg-primary-black text-primary-white text-center">
        <div className="max-w-7xl mx-auto px-5">
          <h1 className="font-display text-[2.5rem] mb-2 text-primary-yellow">
            World Budo Arts Blog
          </h1>
          <p className="text-[1.2rem] italic">
            Explore the latest insights, techniques, and stories from the world of martial arts.
          </p>
        </div>
      </section>
      
      {/* Blog List Section */}
      <section className="w-full py-20 bg-[#f5f5f5]">
        <div className="max-w-7xl mx-auto px-5">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {blogPosts.length === 0 ? (
              <div className="col-span-full text-center py-10 bg-white rounded-lg shadow-md">
                <p className="text-gray-700">No blog posts available at the moment.</p>
              </div>
            ) : (
              blogPosts.map((post) => (
                <div 
                  key={post.id} 
                  className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-xl 
                           transform hover:-translate-y-1 transition-all duration-300"
                >
                  <div className="relative h-52 overflow-hidden">
                    <img 
                      src={getImageUrl(post)}
                      alt={post.attributes.title}
                      className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                    />
                  </div>
                  <div className="p-6 flex flex-col h-[calc(100%-13rem)]">
                    <h2 className="text-xl font-bold mb-4 text-gray-900 hover:text-primary-red transition-colors duration-300">
                      <Link to={`/blog/${post.attributes.slug}`} className="hover:no-underline">
                        {post.attributes.title}
                      </Link>
                    </h2>
                    <p className="text-gray-600 mb-6 flex-grow line-clamp-3">
                      {post.attributes.content && 
                        post.attributes.content[0]?.children?.[0]?.text.substring(0, 150) + '...'}
                    </p>
                    <Link 
                      to={`/blog/${post.attributes.slug}`}
                      className="inline-block px-6 py-3 bg-primary-red text-primary-white font-bold 
                               rounded-lg hover:bg-primary-yellow hover:text-primary-black 
                               transition-all duration-300 hover:no-underline text-center"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogPage;