import React, { useState, useEffect } from 'react';
import { FaEnvelope, FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';
import api from '../../api';
import SEOHead from '../../components/SEOHead';
import SignupModal from '../../components/SignUpModal/SignUpModal';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [formStatus, setFormStatus] = useState({ type: '', message: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);

  const handleOpenSignupModal = () => {
    setIsSignupModalOpen(true);
  };

  const handleCloseSignupModal = () => {
    setIsSignupModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setFormStatus({ type: '', message: '' });

    try {
      // Replace with your actual contact endpoint
      const response = await api.post('/contact', formData);
      setFormStatus({
        type: 'success',
        message: 'Thank you for your message. We will get back to you soon!'
      });
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
    } catch (error) {
      console.error('Contact form error:', error);
      setFormStatus({
        type: 'error',
        message: 'Failed to send message. Please try again.'
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="font-main w-full" id="main-content">
      <SEOHead
        title="Contact Us | World Budo Arts"
        description="Get in touch with World Budo Arts. Contact us for any inquiries about our martial arts programs."
        path="/contact"
      />
      
      <section className="w-full py-20 bg-primary-black text-primary-white text-center">
        <div className="max-w-7xl mx-auto px-5">
          <h1 className="font-display text-[2.5rem] mb-2 text-primary-yellow">Contact Us</h1>
          <p className="text-[1.2rem] italic">Get in touch with World Budo Arts</p>
        </div>
      </section>

      <div className="max-w-7xl mx-auto px-5 py-16">
        <div className="flex flex-col md:flex-row justify-between gap-10">
          {/* Contact Form Container */}
          <div className="flex-1 bg-gray-50 p-8 rounded-xl shadow-md hover:translate-y-[-5px] hover:shadow-xl transition-all duration-300">
            <h2 className="text-2xl font-bold text-primary-red mb-6">Send Us a Message</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-2">
                <label htmlFor="name" className="block text-gray-700">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:border-primary-red transition-colors duration-300"
                  aria-label="Name input to send a message to Shihan Tom"
                />
              </div>

              <div className="space-y-2">
                <label htmlFor="email" className="block text-gray-700">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:border-primary-red transition-colors duration-300"
                  aria-label="Email input to send a message to Shihan Tom"
                />
              </div>

              <div className="space-y-2">
                <label htmlFor="subject" className="block text-gray-700">Subject</label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                  required
                  className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:border-primary-red transition-colors duration-300"
                  aria-label="Subject input to send a message to Shihan Tom"
                />
              </div>

              <div className="space-y-2">
                <label htmlFor="message" className="block text-gray-700">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                  className="w-full px-4 py-2 border border-gray-200 rounded-lg h-40 resize-y focus:outline-none focus:border-primary-red transition-colors duration-300"
                  aria-label="Message input to send a message to Shihan Tom"
                ></textarea>
              </div>

              <button 
                type="submit" 
                className="w-full py-3 bg-primary-red text-primary-white text-lg font-bold rounded-lg
                         hover:bg-primary-yellow hover:text-primary-black transform hover:scale-[1.02]
                         transition-all duration-300 disabled:opacity-50"
                disabled={isLoading}
              >
                {isLoading ? 'Sending...' : 'Send Message'}
              </button>
            </form>
            {formStatus.message && (
              <div className={`mt-4 p-4 ${
                formStatus.type === 'success' ? 'bg-green-500' : 'bg-red-500'
              } text-white rounded-lg text-center animate-fadeIn`}>
                {formStatus.message}
              </div>
            )}
          </div>

          {/* Contact Info Container */}
          <div className="flex-1 bg-gray-50 p-8 rounded-xl shadow-md hover:translate-y-[-5px] hover:shadow-xl transition-all duration-300">
            <h2 className="text-2xl font-bold text-primary-red mb-6">Contact Information</h2>
            
            <div className="space-y-6">
              <div className="flex items-center gap-3">
                <FaEnvelope className="text-xl text-primary-red" />
                <a 
                  href="mailto:shihancallahan@gmail.com" 
                  className="text-gray-700 hover:text-primary-yellow transition-colors duration-300"
                >
                  shihancallahan@gmail.com
                </a>
              </div>

              {/* Social Links */}
              <div className="pt-6 border-t border-gray-200">
                <h3 className="text-xl font-bold text-primary-red mb-4">Follow Us</h3>
                <div className="flex gap-6">
                  <a 
                    href="https://facebook.com/worldbudoarts" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-2xl text-gray-700 hover:text-primary-red transform hover:scale-125 transition-all duration-300"
                    aria-label="Facebook link for World Budo Arts"
                  >
                    <FaFacebook />
                  </a>
                  <a 
                    href="https://instagram.com/world_budo_arts" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-2xl text-gray-700 hover:text-primary-red transform hover:scale-125 transition-all duration-300"
                    aria-label="Instagram link for World Budo Arts"
                  >
                    <FaInstagram />
                  </a>
                  <a 
                    href="https://youtube.com/@shihantomcallahan" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-2xl text-gray-700 hover:text-primary-red transform hover:scale-125 transition-all duration-300"
                    aria-label="YouTube link for World Budo Arts"
                  >
                    <FaYoutube />
                  </a>
                </div>
              </div>

              {/* Sign Up Section */}
              <div className="pt-6 border-t border-gray-200">
                <h3 className="text-xl font-bold text-primary-red mb-2">Get a Free Martial Arts Lesson</h3>
                <p className="text-gray-600 mb-4">
                  Join our community and receive an exclusive lesson from Shihan Tom.
                </p>
                <button
                  onClick={handleOpenSignupModal}
                  className="w-full py-3 bg-primary-red text-white rounded-lg font-bold
                           hover:bg-primary-yellow hover:text-primary-black 
                           transition-all duration-300 transform hover:scale-[1.02]"
                >
                  Get Free Lesson
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SignupModal
        isOpen={isSignupModalOpen}
        onClose={handleCloseSignupModal}
      />
    </div>
  );
};

export default ContactPage;