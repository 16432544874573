// Helper function to parse Strapi event dates safely
const parseEventDate = (event) => {
  try {
    // Add 24 hours (in milliseconds) to the date
    return new Date(new Date(event.attributes.date).getTime() + (24 * 60 * 60 * 1000));
  } catch (error) {
    console.error('Error parsing date for event:', event);
    return new Date(0); // Return earliest possible date if parsing fails
  }
};

// Function to check if an event is upcoming
const isUpcomingEvent = (event) => {
  const eventDate = parseEventDate(event);
  const now = new Date();
  return eventDate >= now;
};

// Main sorting function for all events
const sortEvents = (events) => {
  if (!Array.isArray(events)) return [];

  const now = new Date();

  // Separate upcoming and past events
  const upcomingEvents = [];
  const pastEvents = [];

  events.forEach(event => {
    const eventDate = parseEventDate(event);
    if (eventDate >= now) {
      upcomingEvents.push(event);
    } else {
      pastEvents.push(event);
    }
  });

  // Sort upcoming events by closest date first
  upcomingEvents.sort((a, b) => {
    const dateA = parseEventDate(a);
    const dateB = parseEventDate(b);
    return dateA - dateB;
  });

  // Sort past events by most recent first
  pastEvents.sort((a, b) => {
    const dateA = parseEventDate(a);
    const dateB = parseEventDate(b);
    return dateB - dateA;
  });

  // Return combined array with upcoming events first
  return [...upcomingEvents, ...pastEvents];
};

const getUpcomingEventsForHome = (events) => {
  if (!Array.isArray(events)) return [];
  
  const now = new Date();
  
  // Filter to only upcoming events
  const upcomingEvents = events.filter(event => {
    const eventDate = new Date(event.attributes.date);
    return eventDate >= now;
  });
  
  // Sort by closest date
  upcomingEvents.sort((a, b) => {
    const dateA = new Date(a.attributes.date);
    const dateB = new Date(b.attributes.date);
    return dateA - dateB;
  });
  
  return upcomingEvents;
};

export { sortEvents, getUpcomingEventsForHome, isUpcomingEvent };