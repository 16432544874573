import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaYoutube, FaTiktok } from 'react-icons/fa';
import SignupModal from '../SignUpModal/SignUpModal';

const Footer = () => {
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);

  const handleOpenSignupModal = () => {
    setIsSignupModalOpen(true);
  };

  const handleCloseSignupModal = () => {
    setIsSignupModalOpen(false);
  };

  return (
    <footer className="bg-primary-black text-primary-white py-10 pb-5 font-main">
      <div className="container">
        <div className="flex flex-wrap justify-between md:flex-row flex-col">
          <div className="flex-1 mr-0 md:mr-10 mb-5 flex flex-col items-center">
            <h3 className="text-primary-yellow text-xl mb-4">Contact Us</h3>
            <a
              href="mailto:shihancallahan@gmail.com"
              className="text-primary-white hover:text-primary-yellow transition-colors duration-300 no-underline mb-5"
            >
              shihancallahan@gmail.com
            </a>
            <div className="w-full mt-5 border-t border-white/10 pt-5 flex flex-col items-center">
              <h4 className="text-primary-yellow text-lg font-bold mb-4">
                Get a Free Martial Arts Lesson
              </h4>
              <button
                onClick={handleOpenSignupModal}
                className="w-full md:w-auto bg-primary-yellow text-primary-black font-bold py-3 px-5 
                  rounded-lg hover:bg-primary-red hover:text-primary-white transform 
                  hover:-translate-y-0.5 transition-all duration-300 tracking-wider"
              >
                Get Free Lesson
              </button>
            </div>
          </div>

          <div className="flex-1 mr-0 md:mr-10 mb-5 flex flex-col items-center">
            <h3 className="text-primary-yellow text-xl mb-4">Quick Links</h3>
            <ul className="text-center w-full">
              {[
                ['About', '/about'],
                ['Blog', '/blog'],
                ['Store', '/store'],
                ['Events', '/events'],
                ['Gallery', '/gallery'],
                ['Contact', '/contact'],
              ].map(([name, path]) => (
                <li key={path} className="mb-2.5">
                  <Link
                    to={path}
                    className="text-primary-white hover:text-primary-yellow transition-colors duration-300 hover:no-underline"
                  >
                    {name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="flex-1 mb-5 flex flex-col items-center">
            <h3 className="text-primary-yellow text-xl mb-4">Follow Us</h3>
            <div className="flex justify-center gap-5 w-full mt-2.5">
              {[
                { Icon: FaFacebook, url: 'https://www.facebook.com/worldbudoarts', label: 'Facebook' },
                { Icon: FaInstagram, url: 'https://www.instagram.com/world_budo_arts', label: 'Instagram' },
                { Icon: FaYoutube, url: 'https://youtube.com/@shihantomcallahan', label: 'YouTube' },
                { Icon: FaTiktok, url: 'https://www.tiktok.com/@tommytikkarate123', label: 'TikTok' },
              ].map(({ Icon, url, label }) => (
                <a
                  key={url}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary-white hover:text-primary-yellow hover:-translate-y-0.5 
                           transition-all duration-300 text-2xl inline-flex items-center justify-center"
                  aria-label={`${label} link for World Budo Arts`}
                >
                  <Icon />
                </a>
              ))}
            </div>
          </div>
        </div>

        <div className="mt-8 text-center border-t border-white/10 pt-5">
          <p>&copy; {new Date().getFullYear()} World Budo Arts. All rights reserved.</p>
        </div>
      </div>
      <SignupModal
        isOpen={isSignupModalOpen}
        onClose={handleCloseSignupModal}
      />
    </footer>
  );
};

export default Footer;