import React, { useState } from 'react';
import SEOHead from '../../components/SEOHead';
import SignupModal from '../../components/SignUpModal/SignUpModal';

const StoreComingSoon = () => {
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);

  const handleOpenSignupModal = () => {
    setIsSignupModalOpen(true);
  };

  const handleCloseSignupModal = () => {
    setIsSignupModalOpen(false);
  };

  return (
    <div className="relative flex flex-col min-h-screen font-main z-0" id="main-content">
      <SEOHead 
        title="Store Coming Soon | World Budo Arts"
        description="Our martial arts store is coming soon. Join our waitlist and receive a free martial arts lesson from Shihan Tom!"
        path="/store"
      />

      <section className="relative w-full py-20 bg-primary-black text-primary-white text-center">
        <div className="max-w-7xl mx-auto px-5">
          <h1 className="font-display text-[2.5rem] mb-2 text-primary-yellow">
            World Budo Arts Store
          </h1>
          <p className="text-[1.2rem] italic">Coming Soon</p>
        </div>
      </section>

      <section className="w-full py-20 bg-primary-white">
        <div className="max-w-7xl mx-auto px-5 text-center">
          <h2 className="text-2xl font-bold text-primary-red mb-2">
            Thank you for your patience
          </h2>
          <div className="max-w-2xl mx-auto space-y-4">
            <p className="text-lg mb-4">
              We're working hard to bring you the best martial arts gear and apparel.
            </p>
            <p className="text-lg">
              Join our waitlist today and receive a free martial arts lesson from Shihan Tom!
            </p>
          </div>

          <div className="mt-8">
            <button
              onClick={handleOpenSignupModal}
              className="inline-block px-8 py-4 bg-primary-red text-primary-white font-bold 
                rounded-lg transition-all duration-300 transform hover:bg-primary-yellow 
                hover:text-primary-black hover:-translate-y-0.5 hover:shadow-lg"
            >
              Join Waitlist & Get Free Lesson
            </button>
          </div>
        </div>
      </section>

      <SignupModal
        isOpen={isSignupModalOpen}
        onClose={handleCloseSignupModal}
      />
    </div>
  );
};

export default StoreComingSoon;