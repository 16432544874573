import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SEOHead from '../../components/SEOHead';
import { fetchGalleryItems } from '../../api';

const GalleryCard = ({ item }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (isExpanded) {
      // Instead of adding 'overflow-hidden', add padding to prevent layout shift
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.style.paddingRight = `${scrollbarWidth}px`;
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.paddingRight = '';
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.paddingRight = '';
      document.body.style.overflow = '';
    };
  }, [isExpanded]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && isExpanded) {
        setIsExpanded(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [isExpanded]);

  const getImageUrl = (item) => {
    if (item.attributes?.image?.data?.attributes?.url) {
      return `${process.env.REACT_APP_STRAPI_URL}${item.attributes.image.data.attributes.url}`;
    } else if (item.image?.url) {
      return `${process.env.REACT_APP_STRAPI_URL}${item.image.url}`;
    }
    return '/placeholder-image.jpg';
  };

  return (
    <div
      className={`aspect-[3/4] relative transition-opacity duration-300
        ${isExpanded ? 'fixed inset-0 z-40 bg-transparent' : ''}`}
      onClick={() => {
        if (isExpanded) {
          setIsExpanded(false);
        }
      }}
    >
      {!isExpanded ? (
        // Preview Card
        <div
          className="w-full h-full cursor-pointer rounded-2xl overflow-hidden bg-primary-black 
                   transition-all duration-400 relative"
          onClick={() => setIsExpanded(true)}
        >
          <img
            src={getImageUrl(item)}
            alt={item.attributes?.title || item.title || 'Gallery Image'}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black/70 flex flex-col justify-center items-center">
            <h3 className="font-display text-primary-yellow text-2xl mb-4 px-5 text-center">
              {item.attributes?.title || item.title}
            </h3>
            <p className="text-primary-white text-sm">Click for full story</p>
          </div>
        </div>
      ) : (
        // Expanded View
        <>
          {/* Backdrop */}
          <div className="fixed inset-0 bg-black/85 z-40" />

          {/* Modal Container - Added overflow-y-auto */}
          <div
            className="fixed inset-0 z-50 p-4 md:p-8 overflow-y-auto"
            onClick={(e) => {
              e.stopPropagation();
              setIsExpanded(false);
            }}
          >
            {/* Modal Content - Made scrollable */}
            <div
              className="relative w-full max-w-6xl mx-auto bg-primary-black rounded-xl p-4 md:p-8 
                         animate-scaleIn my-4 md:my-8"
              onClick={(e) => e.stopPropagation()}
            >
              {/* Close Button */}
              <button
                className="absolute top-4 right-4 text-primary-white bg-black/20 hover:bg-white/20
                           w-10 h-10 rounded-full flex items-center justify-center text-2xl
                           transition-colors duration-300 z-50"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsExpanded(false);
                }}
                aria-label="Close gallery item"
              >
                ×
              </button>

              {/* Content Container - Adjusted flex and scroll behavior */}
              <div className="flex flex-col md:flex-row gap-8">
                {/* Image Section - Adjusted height constraints */}
                <div className="w-full md:w-1/2 flex items-start justify-center">
                  <img
                    src={getImageUrl(item)}
                    alt={item.attributes?.title || item.title || 'Gallery Image'}
                    className="w-full h-auto max-h-[50vh] md:max-h-[70vh] object-contain rounded-lg"
                  />
                </div>

                {/* Text Section - Made independently scrollable */}
                <div className="w-full md:w-1/2 flex flex-col text-primary-white max-h-[50vh] md:max-h-[70vh]">
                  <h3 className="text-2xl md:text-3xl font-bold text-primary-yellow mb-6 text-center">
                    {item.attributes?.title || item.title}
                  </h3>
                  <div className="flex-1 overflow-y-auto pr-4 
                                [&::-webkit-scrollbar]:w-2 
                                [&::-webkit-scrollbar-track]:bg-white/10 
                                [&::-webkit-scrollbar-thumb]:bg-primary-red 
                                [&::-webkit-scrollbar-thumb]:rounded-full">
                    <p className="text-lg leading-relaxed">
                      {item.attributes?.story || item.story}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

// Rest of GalleryPage component remains the same
const GalleryPage = () => {
  const [galleryItems, setGalleryItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadGalleryItems = async () => {
      try {
        const data = await fetchGalleryItems();
        setGalleryItems(Array.isArray(data) ? data : []);
      } catch (err) {
        console.error('Error fetching gallery items:', err);
        setError(err.message || 'Failed to fetch gallery items');
      } finally {
        setLoading(false);
      }
    };

    loadGalleryItems();
    window.scrollTo(0, 0);
  }, []);

  if (loading) return (
    <div className="font-main">
      <div className="max-w-7xl mx-auto px-5">
        <div className="text-center py-20 text-primary-red text-xl">Loading gallery...</div>
      </div>
    </div>
  );

  if (error) return (
    <div className="font-main">
      <div className="max-w-7xl mx-auto px-5">
        <div className="text-center py-20 text-primary-red">{error}</div>
      </div>
    </div>
  );

  return (
    <div className="font-main" id="main-content">
      <SEOHead
        title="Gallery | World Budo Arts"
        description="Explore our collection of martial arts moments and memories at World Budo Arts."
        path="/gallery"
      />

      <section className="w-full py-20 bg-primary-black text-primary-white text-center">
        <div className="max-w-7xl mx-auto px-5">
          <h1 className="font-display text-[2.5rem] mb-2 text-primary-yellow">Gallery</h1>
          <p className="text-[1.2rem] italic">Journey through our martial arts moments</p>
        </div>
      </section>

      <section className="bg-primary-white py-10 border-b border-black/10">
        <div className="max-w-3xl mx-auto px-5 text-center">
          <h2 className="font-display text-2xl text-primary-red mb-5">Welcome to Our Journey</h2>
          <p className="text-lg leading-relaxed mb-4">
            As the founder of World Budo Arts, I'm proud to share with you this collection of moments that capture our martial arts journey. Each image tells a story of dedication, growth, and the unbreakable bonds formed through our practice.
          </p>
          <p className="text-lg leading-relaxed mb-4">
            These photographs represent more than just events - they're milestones in our continuing evolution as martial artists and as a community.
          </p>
          <Link
            to="/about"
            className="inline-block mt-8 mb-5 font-['Brush_Script_MT',cursive] text-4xl 
                       text-primary-red hover:text-primary-yellow transition-colors duration-300
                       no-underline shadow-sm"
          >
            ~ Shihan Tom Callahan
          </Link>
          <div className="inline-block mt-5 px-6 py-4 bg-gray-100 rounded-lg italic">
            Hover over images to preview, and click to read the full story behind each moment.
          </div>
        </div>
      </section>

      <section className="py-16 bg-gray-100">
        <div className="max-w-7xl mx-auto px-5">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {galleryItems.length > 0 ? (
              galleryItems.map((item) => (
                <GalleryCard key={item.id} item={item} />
              ))
            ) : (
              <div className="col-span-full text-center py-10">No gallery items found</div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default GalleryPage;