import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FaShoppingCart, FaMinus, FaPlus } from 'react-icons/fa';
import { useCart } from '../../context/CartContext';
import './ProductPage.css';

const ProductPage = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { addItem, getCartItemsCount } = useCart();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        // In a real application, you would fetch from your API
        // For now, we'll use mock data
        const mockProduct = {
          id: productId,
          name: 'Karate Gi',
          price: 79.99,
          description: 'Professional grade karate uniform perfect for training and competition.',
          images: ['/images/karate-gi-1.jpg', '/images/karate-gi-2.jpg'],
          sizes: ['S', 'M', 'L', 'XL'],
          features: [
            'Lightweight and durable material',
            'Reinforced stitching',
            'Competition approved',
            'Easy care fabric'
          ],
          inStock: true
        };
        
        setProduct(mockProduct);
        setLoading(false);
      } catch (err) {
        setError('Failed to load product');
        setLoading(false);
      }
    };

    fetchProduct();
    window.scrollTo(0, 0);
  }, [productId]);

  const handleQuantityChange = (newQuantity) => {
    setQuantity(Math.max(1, newQuantity));
  };

  const handleAddToCart = () => {
    if (product) {
      addItem({
        id: product.id,
        name: product.name,
        price: product.price,
        quantity: quantity,
        image: product.images[0]
      });
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!product) return <div>Product not found</div>;

  return (
    <div className="product-page" id="main-content">
      <div className="container">
        <div className="product-navigation">
          <Link to="/store" className="back-link">&larr; Back to Store</Link>
          <Link to="/cart" className="cart-link">
            <FaShoppingCart />
            <span className="cart-count">{getCartItemsCount()}</span>
          </Link>
        </div>

        <div className="product-content">
          <div className="product-gallery">
            {product.images.map((image, index) => (
              <img 
                key={index}
                src={image}
                alt={`${product.name} - View ${index + 1}`}
                className="product-image"
              />
            ))}
          </div>

          <div className="product-details">
            <h1 className="product-title">{product.name}</h1>
            <p className="product-price">${product.price.toFixed(2)}</p>
            <p className="product-description">{product.description}</p>

            <div className="product-sizes">
              <h3>Available Sizes</h3>
              <div className="size-options">
                {product.sizes.map(size => (
                  <button key={size} className="size-button">
                    {size}
                  </button>
                ))}
              </div>
            </div>

            <div className="product-features">
              <h3>Features</h3>
              <ul>
                {product.features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
            </div>

            <div className="product-actions">
              <div className="quantity-selector">
                <button 
                  onClick={() => handleQuantityChange(quantity - 1)}
                  className="quantity-button"
                >
                  <FaMinus />
                </button>
                <input
                  type="number"
                  min="1"
                  value={quantity}
                  onChange={(e) => handleQuantityChange(parseInt(e.target.value))}
                  className="quantity-input"
                />
                <button 
                  onClick={() => handleQuantityChange(quantity + 1)}
                  className="quantity-button"
                >
                  <FaPlus />
                </button>
              </div>

              <button 
                className="add-to-cart-button"
                onClick={handleAddToCart}
                disabled={!product.inStock}
              >
                {product.inStock ? 'Add to Cart' : 'Out of Stock'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;