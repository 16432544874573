import React, { useEffect, useState } from 'react';

const LoadingScreen = ({ onLoadingComplete }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [showHajime, setShowHajime] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowHajime(true);
      setTimeout(() => {
        setIsVisible(false);
        if (onLoadingComplete) onLoadingComplete();
      }, 1000);
    }, 2000);

    return () => clearTimeout(timer);
  }, [onLoadingComplete]);

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-primary-black flex justify-center items-center z-[9999]">
      <div className={`relative flex flex-col items-center gap-5 transition-opacity duration-500 
        ${showHajime ? 'opacity-0' : 'opacity-100'}`}>
        <div className="absolute w-[200px] h-[200px] bg-primary-yellow rounded-full opacity-0 
          animate-[riseSun_2s_ease-out_forwards] z-10 transform translate-y-5">
        </div>
        
        <img 
          src="/logo.png" 
          alt="World Budo Arts Logo" 
          className="w-[150px] h-auto relative z-20"
        />
        
        <p className="text-primary-white italic text-xl mt-5 opacity-0 
          animate-[fadeIn_1s_ease-out_1s_forwards] relative z-20">
          Loading your Budo experience...
        </p>
      </div>

      {showHajime && (
        <div className="fixed inset-0 flex justify-center items-center bg-primary-black 
          animate-[gentleFade_1s_ease-out_forwards]">
          <span className="text-primary-red font-bold opacity-0 md:text-[15vw] text-[30vw]
            animate-[hajimeFade_1s_ease-out_forwards]">
            押忍
          </span>
        </div>
      )}
    </div>
  );
};

export default LoadingScreen;