import React, { useState } from 'react';
import { FaShoppingCart, FaCreditCard, FaTrash, FaMinus, FaPlus } from 'react-icons/fa';
import { SiVisa, SiMastercard, SiAmericanexpress, SiDiscover } from 'react-icons/si';
import './CartCheckoutPage.css';

const CartCheckoutPage = () => {
  const [step, setStep] = useState('cart');
  const [cart, setCart] = useState([
    { 
      id: 1, 
      name: 'Karate Gi', 
      price: 79.99, 
      quantity: 1, 
      image: '/images/karate-gi.jpg' 
    },
    { 
      id: 2, 
      name: 'Training Gloves', 
      price: 34.99, 
      quantity: 2, 
      image: '/images/training-gloves.jpg' 
    },
  ]);
  const [orderComplete, setOrderComplete] = useState(false);
  const [orderNumber, setOrderNumber] = useState('');
  const [contactInfo, setContactInfo] = useState({ email: '', phone: '' });
  const [shippingDetails, setShippingDetails] = useState({
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
  });
  const [paymentDetails, setPaymentDetails] = useState({
    cardNumber: '',
    expDate: '',
    cvv: '',
  });

  const totalPrice = cart.reduce((total, item) => total + item.price * item.quantity, 0);
  const shippingCost = 4.95;
  const estimatedTax = totalPrice * 0.08; // 8% tax rate
  const orderTotal = totalPrice + shippingCost + estimatedTax;

  const states = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 
    'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 
    'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 
    'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 
    'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 
    'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 
    'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 
    'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 
    'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 
    'West Virginia', 'Wisconsin', 'Wyoming'
  ];

  const removeFromCart = (id) => {
    setCart(cart.filter(item => item.id !== id));
  };

  const updateQuantity = (id, newQuantity) => {
    setCart(cart.map(item => 
      item.id === id ? { ...item, quantity: Math.max(1, newQuantity) } : item
    ));
  };

  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setContactInfo(prev => ({ ...prev, [name]: value }));
  };

  const handleShippingChange = (e) => {
    const { name, value } = e.target;
    setShippingDetails(prev => ({ ...prev, [name]: value }));
  };

  const handlePaymentChange = (e) => {
    let { name, value } = e.target;
    
    // Format card number with spaces
    if (name === 'cardNumber') {
      value = value.replace(/\D/g, '').replace(/(\d{4})(?=\d)/g, '$1-');
      value = value.substring(0, 19);
    } 
    // Format expiration date
    else if (name === 'expDate') {
      value = value.replace(/\D/g, '').replace(/(\d{2})(?=\d)/, '$1/');
      value = value.substring(0, 5);
    } 
    // Format CVV
    else if (name === 'cvv') {
      value = value.replace(/\D/g, '').substring(0, 4);
    }
    
    setPaymentDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleCheckout = (e) => {
    e.preventDefault();
    setOrderNumber(Math.floor(10000000 + Math.random() * 90000000).toString());
    setOrderComplete(true);
    setStep('confirmation');
  };

  return (
    <div className="cart-checkout-page" id="main-content">
      <section className="intro">
        <div className="container">
          <h1><FaShoppingCart /> Your Shopping Cart</h1>
          <p className="subtitle">Complete your purchase of high-quality martial arts gear</p>
        </div>
      </section>

      <div className="container">
        {step === 'cart' && (
          <div className="cart-checkout-content">
            <div className="cart-checkout-section">
              <h2>Cart Items</h2>
              {cart.length === 0 ? (
                <div className="empty-cart">
                  <p>Your cart is empty</p>
                  <button 
                    className="btn btn-primary"
                    onClick={() => window.history.back()}
                  >
                    Continue Shopping
                  </button>
                </div>
              ) : (
                <>
                  <table className="cart-checkout-table">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Subtotal</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {cart.map(item => (
                        <tr key={item.id} className="cart-checkout-item">
                          <td>
                            <div className="cart-checkout-item-info">
                              <img 
                                src={item.image} 
                                alt={item.name} 
                                className="cart-checkout-item-image" 
                              />
                              <span className="cart-checkout-item-name">{item.name}</span>
                            </div>
                          </td>
                          <td>${item.price.toFixed(2)}</td>
                          <td>
                            <div className="cart-checkout-item-quantity">
                              <button 
                                onClick={() => updateQuantity(item.id, item.quantity - 1)}
                              >
                                <FaMinus />
                              </button>
                              <input
                                type="number"
                                min="1"
                                value={item.quantity}
                                onChange={(e) => updateQuantity(item.id, parseInt(e.target.value))}
                              />
                              <button 
                                onClick={() => updateQuantity(item.id, item.quantity + 1)}
                              >
                                <FaPlus />
                              </button>
                            </div>
                          </td>
                          <td>${(item.price * item.quantity).toFixed(2)}</td>
                          <td>
                            <button 
                              className="cart-checkout-btn-remove"
                              onClick={() => removeFromCart(item.id)}
                            >
                              <FaTrash />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className="cart-checkout-summary">
                    <div className="cart-checkout-summary-row">
                      <span>Subtotal:</span>
                      <span>${totalPrice.toFixed(2)}</span>
                    </div>
                    <div className="cart-checkout-summary-row">
                      <span>Shipping:</span>
                      <span>${shippingCost.toFixed(2)}</span>
                    </div>
                    <div className="cart-checkout-summary-row">
                      <span>Estimated Tax:</span>
                      <span>${estimatedTax.toFixed(2)}</span>
                    </div>
                    <div className="cart-checkout-summary-row cart-checkout-total">
                      <span>Total:</span>
                      <span>${orderTotal.toFixed(2)}</span>
                    </div>
                  </div>

                  <div className="cart-checkout-actions">
                    <button 
                      className="cart-checkout-btn-primary"
                      onClick={() => setStep('checkout')}
                    >
                      Proceed to Checkout
                    </button>
                    <button 
                      className="cart-checkout-btn-secondary"
                      onClick={() => window.history.back()}
                    >
                      Continue Shopping
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        )}

        {step === 'checkout' && (
          <div className="cart-checkout-content">
            <div className="cart-checkout-section">
              <h2>Checkout</h2>
              <form onSubmit={handleCheckout}>
                {/* Contact Information */}
                <div className="cart-checkout-form-group">
                  <h3>Contact Information</h3>
                  <input
                    type="email"
                    name="email"
                    value={contactInfo.email}
                    onChange={handleContactChange}
                    placeholder="Email Address*"
                    required
                  />
                  <input
                    type="tel"
                    name="phone"
                    value={contactInfo.phone}
                    onChange={handleContactChange}
                    placeholder="Phone Number (Optional)"
                  />
                </div>

                {/* Shipping Information */}
                <div className="cart-checkout-form-group">
                  <h3>Shipping Address</h3>
                  <input
                    type="text"
                    name="name"
                    value={shippingDetails.name}
                    onChange={handleShippingChange}
                    placeholder="Full Name*"
                    required
                  />
                  <input
                    type="text"
                    name="address"
                    value={shippingDetails.address}
                    onChange={handleShippingChange}
                    placeholder="Street Address*"
                    required
                  />
                  <div className="cart-checkout-location-group">
                    <input
                      type="text"
                      name="city"
                      value={shippingDetails.city}
                      onChange={handleShippingChange}
                      placeholder="City*"
                      required
                    />
                    <select
                      name="state"
                      value={shippingDetails.state}
                      onChange={handleShippingChange}
                      required
                    >
                      <option value="">State*</option>
                      {states.map(state => (
                        <option key={state} value={state}>{state}</option>
                      ))}
                    </select>
                    <input
                      type="text"
                      name="zip"
                      value={shippingDetails.zip}
                      onChange={handleShippingChange}
                      placeholder="ZIP Code*"
                      required
                    />
                  </div>
                </div>

                {/* Payment Information */}
                <div className="cart-checkout-form-group">
                  <h3>Payment Method</h3>
                  <div className="cart-checkout-payment-option">
                    <input
                      type="radio"
                      id="creditCard"
                      name="paymentMethod"
                      defaultChecked
                    />
                    <label 
                      htmlFor="creditCard" 
                      className="cart-checkout-credit-card-label"
                    >
                      <FaCreditCard /> Credit Card
                      <span className="cart-checkout-card-icons">
                        <SiVisa className="card-icon" />
                        <SiMastercard className="card-icon" />
                        <SiAmericanexpress className="card-icon" />
                        <SiDiscover className="card-icon" />
                      </span>
                    </label>
                  </div>
                  <div className="cart-checkout-credit-card-inputs">
                    <input
                      type="text"
                      name="cardNumber"
                      value={paymentDetails.cardNumber}
                      onChange={handlePaymentChange}
                      placeholder="Card Number"
                      required
                    />
                    <div className="cart-checkout-card-details">
                      <input
                        type="text"
                        name="expDate"
                        value={paymentDetails.expDate}
                        onChange={handlePaymentChange}
                        placeholder="MM/YY"
                        required
                      />
                      <input
                        type="text"
                        name="cvv"
                        value={paymentDetails.cvv}
                        onChange={handlePaymentChange}
                        placeholder="CVV"
                        required
                      />
                    </div>
                  </div>
                </div>

                <button type="submit" className="cart-checkout-btn-primary">
                  Place Order
                </button>
              </form>
            </div>

            <div className="cart-checkout-summary">
              <h3>Order Summary</h3>
              {cart.map(item => (
                <div key={item.id} className="cart-checkout-summary-item">
                  <span>{item.quantity} x {item.name}</span>
                  <span>${(item.price * item.quantity).toFixed(2)}</span>
                </div>
              ))}
              <div className="cart-checkout-summary-total">
                <span>Total:</span>
                <span>${orderTotal.toFixed(2)}</span>
              </div>
            </div>
          </div>
        )}

        {step === 'confirmation' && (
          <div className="cart-checkout-content">
            <div className="cart-checkout-confirmation">
              <h2>Thank you for your order!</h2>
              <p>Order #{orderNumber}</p>
              <p>We'll send you an email confirmation shortly.</p>
              
              <div className="cart-checkout-order-details">
                <div>
                  <h3>Shipping to:</h3>
                  <p>{shippingDetails.name}</p>
                  <p>{shippingDetails.address}</p>
                  <p>{shippingDetails.city}, {shippingDetails.state} {shippingDetails.zip}</p>
                </div>
                <div>
                  <h3>Order Summary</h3>
                  {cart.map(item => (
                    <div key={item.id} className="cart-checkout-summary-item">
                      <span>{item.quantity} x {item.name}</span>
                      <span>${(item.price * item.quantity).toFixed(2)}</span>
                    </div>
                  ))}
                  <div className="cart-checkout-summary-total">
                    <span>Total:</span>
                    <span>${orderTotal.toFixed(2)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CartCheckoutPage;