import React, { useState, useEffect } from 'react';
import { X, Check, Loader2, AlertCircle } from 'lucide-react';
import api from '../../api'; // Import your API configuration

const SignupModal = ({ 
  isOpen, 
  onClose, 
  title = "Get Your Free Lesson",
  description = "Join our community and receive your exclusive lesson from Shihan Tom.",
  buttonText = "Get Free Lesson",
}) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: ''
  });
  const [status, setStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const [shake, setShake] = useState(false);

  useEffect(() => {
    if (status === 'error') {
      setShake(true);
      const timer = setTimeout(() => setShake(false), 500);
      return () => clearTimeout(timer);
    }
  }, [status]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('loading');
    
    try {
      const response = await api.post('/newsletter/subscribe', formData);
      
      if (response.status === 200) {
        setStatus('success');
        // Reset form
        setFormData({
          firstName: '',
          lastName: '',
          email: ''
        });
      } else {
        throw new Error(response.data.message || 'Failed to subscribe');
      }
    } catch (error) {
      console.error('Newsletter subscription error:', error);
      setStatus('error');
      if (error.response?.status === 409) {
        setErrorMessage('This email is already subscribed to our newsletter.');
      } else {
        setErrorMessage(error.response?.data?.message || 'Something went wrong. Please try again.');
      }
    }
  };

  const handleInputChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div 
        className={`relative w-full max-w-md bg-white rounded-lg shadow-lg 
          ${shake ? 'animate-shake' : ''}`}
      >
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 
            transition-colors"
        >
          <X className="w-6 h-6" />
        </button>

        {/* Content Container */}
        <div className="p-6">
          {status !== 'success' ? (
            <>
              {/* Form View */}
              <div className="text-center mb-6">
                <h3 className="text-2xl font-bold text-gray-900 mb-2">{title}</h3>
                <p className="text-gray-600">{description}</p>
              </div>

              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="space-y-4">
                  <div>
                    <input
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      required
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg 
                        focus:ring-2 focus:ring-red-500 focus:border-transparent"
                      onChange={handleInputChange}
                      disabled={status === 'loading'}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      required
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg 
                        focus:ring-2 focus:ring-red-500 focus:border-transparent"
                      onChange={handleInputChange}
                      disabled={status === 'loading'}
                    />
                  </div>
                  <div>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      required
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg 
                        focus:ring-2 focus:ring-red-500 focus:border-transparent"
                      onChange={handleInputChange}
                      disabled={status === 'loading'}
                    />
                  </div>
                </div>

                {status === 'error' && (
                  <div className="flex items-center gap-2 text-red-500 text-sm justify-center">
                    <AlertCircle className="w-4 h-4" />
                    <span>{errorMessage}</span>
                  </div>
                )}

                <button
                  type="submit"
                  disabled={status === 'loading'}
                  className="w-full bg-red-600 text-white py-3 rounded-lg font-semibold 
                    hover:bg-red-700 transition-colors disabled:bg-red-400"
                >
                  {status === 'loading' ? (
                    <div className="flex items-center justify-center">
                      <Loader2 className="w-5 h-5 animate-spin mr-2" />
                      Signing up...
                    </div>
                  ) : (
                    buttonText
                  )}
                </button>
              </form>
            </>
          ) : (
            // Success View
            <div className="text-center py-8">
              <div className="mx-auto w-12 h-12 bg-green-100 rounded-full flex 
                items-center justify-center mb-4">
                <Check className="w-6 h-6 text-green-600" />
              </div>
              <h3 className="text-2xl font-bold text-gray-900 mb-2">
                Welcome to World Budo Arts!
              </h3>
              <p className="text-gray-600 mb-6">
                Please check your email for your free lesson from Shihan Tom.
              </p>
              <button
                onClick={onClose}
                className="w-full bg-gray-100 text-gray-800 py-3 rounded-lg 
                  font-semibold hover:bg-gray-200 transition-colors"
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignupModal;