import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SignupModal from '../SignUpModal/SignUpModal';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);

  const handleOpenSignupModal = () => {
    setIsMenuOpen(false);
    setIsSignupModalOpen(true);
  };

  const handleCloseSignupModal = () => {
    setIsSignupModalOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="fixed top-0 left-0 w-full bg-primary-black py-4 z-50">
      <div className="container">
        <div className="flex justify-between items-center w-full">
          <Link to="/" className="block">
            <img
              src="/logo.png"
              alt="World Budo Arts"
              className="h-16 w-auto transition-transform duration-300 hover:scale-110"
            />
          </Link>

          {isMobile ? (
            <>
              <button
                className="text-primary-white text-2xl bg-transparent border-none cursor-pointer md:hidden"
                onClick={toggleMenu}
                aria-label="Toggle menu"
              >
                ☰
              </button>
              <nav
                className={`${isMenuOpen ? 'max-h-[calc(100vh-95px)]' : 'max-h-0'
                  } absolute top-24 left-0 right-0 w-full bg-primary-black overflow-hidden transition-all duration-300 ease-in-out md:relative md:top-0 md:flex-grow md:max-h-full`}
                aria-haspopup="true"
              >
                <ul className="flex flex-col items-center py-5 px-5 md:flex-row md:items-center md:gap-5">
                  <li className="mb-4 md:mb-0">
                    <Link to="/" onClick={toggleMenu} className="text-primary-white font-bold hover:text-primary-yellow transition-colors duration-300 no-underline">
                      Home
                    </Link>
                  </li>
                  <li className="mb-4 md:mb-0">
                    <Link to="/about" onClick={toggleMenu} className="text-primary-white font-bold hover:text-primary-yellow transition-colors duration-300 no-underline">
                      About
                    </Link>
                  </li>
                  <li className="mb-4 md:mb-0">
                    <Link to="/blog" onClick={toggleMenu} className="text-primary-white font-bold hover:text-primary-yellow transition-colors duration-300 no-underline">
                      Blog
                    </Link>
                  </li>
                  <li className="mb-4 md:mb-0">
                    <Link to="/store" onClick={toggleMenu} className="text-primary-white font-bold hover:text-primary-yellow transition-colors duration-300 no-underline">
                      Store
                    </Link>
                  </li>
                  <li className="mb-4 md:mb-0">
                    <Link to="/events" onClick={toggleMenu} className="text-primary-white font-bold hover:text-primary-yellow transition-colors duration-300 no-underline">
                      Events
                    </Link>
                  </li>
                  <li className="mb-4 md:mb-0">
                    <Link to="/gallery" onClick={toggleMenu} className="text-primary-white font-bold hover:text-primary-yellow transition-colors duration-300 no-underline">
                      Gallery
                    </Link>
                  </li>
                  <li className="mb-4 md:mb-0">
                    <Link to="/contact" onClick={toggleMenu} className="text-primary-white font-bold hover:text-primary-yellow transition-colors duration-300 no-underline">
                      Contact
                    </Link>
                  </li>
                  <li className="mt-2 md:mt-0">
                    <button
                      onClick={handleOpenSignupModal}
                      className="inline-block px-6 py-3 bg-primary-red text-primary-white font-bold rounded-lg 
      transition-all duration-300 hover:bg-primary-yellow hover:text-primary-black 
      hover:scale-110 no-underline"
                      aria-label="Get free martial arts lesson"
                    >
                      Free Lesson
                    </button>
                  </li>
                </ul>
              </nav>
            </>
          ) : (
            <>
              <nav className="flex-grow flex justify-center">
                <ul className="flex items-center gap-5">
                  <li>
                    <Link to="/" className="text-primary-white font-bold hover:text-primary-yellow transition-colors duration-300 hover:no-underline">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" className="text-primary-white font-bold hover:text-primary-yellow transition-colors duration-300 hover:no-underline">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" className="text-primary-white font-bold hover:text-primary-yellow transition-colors duration-300 hover:no-underline">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to="/store" className="text-primary-white font-bold hover:text-primary-yellow transition-colors duration-300 hover:no-underline">
                      Store
                    </Link>
                  </li>
                  <li>
                    <Link to="/events" className="text-primary-white font-bold hover:text-primary-yellow transition-colors duration-300 hover:no-underline">
                      Events
                    </Link>
                  </li>
                  <li>
                    <Link to="/gallery" className="text-primary-white font-bold hover:text-primary-yellow transition-colors duration-300 hover:no-underline">
                      Gallery
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" className="text-primary-white font-bold hover:text-primary-yellow transition-colors duration-300 hover:no-underline">
                      Contact
                    </Link>
                  </li>
                </ul>
              </nav>
              <button
                onClick={handleOpenSignupModal}
                className="ml-6 inline-block px-6 py-3 bg-primary-red text-primary-white font-bold 
      rounded-lg transition-all duration-300 hover:bg-primary-yellow 
      hover:text-primary-black hover:scale-110 hover:no-underline"
                aria-label="Get free martial arts lesson"
              >
                Free Lesson
              </button>
            </>
          )}
        </div>
      </div>
      <SignupModal
        isOpen={isSignupModalOpen}
        onClose={handleCloseSignupModal}
      />
    </header>
  );
};

export default Header;