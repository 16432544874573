import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchEvents } from '../../api';
import SEOHead from '../../components/SEOHead';
import { sortEvents, isUpcomingEvent } from '../../components/EventUtility';

const EventsPage = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getEvents = async () => {
      try {
        const response = await fetchEvents();
        if (response && response.data) {
          const sortedEvents = sortEvents(response.data);
          setEvents(sortedEvents);
        } else {
          setError('No events found');
        }
        setLoading(false);
      } catch (err) {
        console.error('Error fetching events:', err);
        setError('Failed to fetch events. Please try again later.');
        setLoading(false);
      }
    };

    getEvents();
    window.scrollTo(0, 0);
  }, []);

  const getImageUrl = (event) => {
    try {
      if (event.attributes.image?.data?.attributes?.url) {
        return `${process.env.REACT_APP_STRAPI_URL}${event.attributes.image.data.attributes.url}`;
      }
    } catch (error) {
      console.error('Error getting image URL:', error);
    }
    return "/images/event-default.jpg";
  };

  const formatDate = (dateString) => {
    try {
      return new Date(dateString).toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Date not available';
    }
  };

  const getDescription = (description) => {
    if (!description) return '';
    if (typeof description === 'string') return description;
    if (Array.isArray(description)) {
      return description.map(block => {
        if (typeof block === 'string') return block;
        if (block.children && Array.isArray(block.children)) {
          return block.children.map(child => child.text || '').join('');
        }
        return '';
      }).join('\n');
    }
    return '';
  };

  if (loading) {
    return (
      <div className="font-main">
        <section className="w-full py-20 bg-primary-black text-primary-white text-center">
          <div className="max-w-7xl mx-auto px-5">
            <h1 className="font-display text-[2.5rem] mb-2 text-primary-yellow">Upcoming Events</h1>
            <p className="text-[1.2rem] italic">Loading events...</p>
          </div>
        </section>
        <div className="py-16 text-center">
          <div className="text-xl text-primary-red">Loading events...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="font-main">
        <section className="w-full py-20 bg-primary-black text-primary-white text-center">
          <div className="max-w-7xl mx-auto px-5">
            <h1 className="font-display text-[2.5rem] mb-2 text-primary-yellow">Upcoming Events</h1>
          </div>
        </section>
        <div className="py-16 px-5">
          <div className="max-w-2xl mx-auto bg-white p-10 rounded-xl shadow-lg text-center">
            <h2 className="text-2xl text-primary-red mb-4">Error</h2>
            <p className="text-gray-600 mb-6">{error}</p>
            <button
              onClick={() => window.location.reload()}
              className="px-6 py-3 bg-primary-red text-white font-bold rounded-lg
                         transition-all duration-300 hover:bg-primary-yellow hover:text-primary-black"
            >
              Try Again
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="font-main" id="main-content">
      <SEOHead
        title="Martial Arts Events & Seminars | World Budo Arts"
        description="Join World Budo Arts' upcoming martial arts events, seminars, and training sessions with Shihan Tom Callahan."
        path="/events"
      />

      {/* Intro Section */}
      <section className="w-full py-20 bg-primary-black text-primary-white text-center">
        <div className="max-w-7xl mx-auto px-5">
          <h1 className="font-display text-[2.5rem] mb-2 text-primary-yellow">Upcoming Events</h1>
          <p className="text-[1.2rem] italic">Join us for these exciting martial arts events and experiences</p>
        </div>
      </section>

      {/* Events List Section */}
      <section className="w-full py-20 bg-gray-100">
        <div className="max-w-7xl mx-auto px-5">
          {events.length === 0 ? (
            <div className="max-w-2xl mx-auto bg-white p-10 rounded-xl shadow-lg text-center">
              <h2 className="text-2xl text-primary-red mb-4">No Upcoming Events</h2>
              <p className="text-gray-600 mb-6">Check back soon for new events!</p>
              <div className="flex flex-col md:flex-row gap-4 justify-center">
                <Link to="/contact" className="btn-primary">Contact Us</Link>
                <Link to="/blog" className="btn-secondary">Read Our Blog</Link>
              </div>
            </div>
          ) : (
            <div className="space-y-10">
              {events.map(event => (
                <div key={event.id}
                  className="relative flex flex-col-reverse md:flex-row-reverse 
                              bg-white rounded-xl overflow-visible shadow-md 
                              transition-all duration-300 hover:-translate-y-1 hover:shadow-xl
                              min-h-[400px] md:min-h-[400px]">
                  {/* Status Badge */}
                  <div className={`absolute top-5 right-[-35px] transform rotate-45 py-1 px-10 
                                text-sm font-bold z-10
                                ${isUpcomingEvent(event)
                      ? 'bg-primary-yellow text-primary-black'
                      : 'bg-gray-500 text-white'}`}>
                    {isUpcomingEvent(event) ? 'Upcoming' : 'Past Event'}
                  </div>

                  {/* Event Info */}
                  <div className="flex-[3] p-8 md:p-10">
                    <div className="space-y-4">
                      <h2 className="text-2xl md:text-3xl font-bold text-primary-red">
                        {event.attributes.name}
                      </h2>
                      <p className="text-gray-700 leading-relaxed">
                        {getDescription(event.attributes.description)}
                      </p>
                      <p className="text-gray-600 text-lg">
                        <span className="font-bold text-primary-red mr-2">Date:</span>
                        {formatDate(event.attributes.date)}
                      </p>
                      <p className="text-gray-600 text-lg">
                        <span className="font-bold text-primary-red mr-2">Location:</span>
                        {event.attributes.location}
                      </p>
                    </div>
                  </div>

                  {/* Event Image */}
                  <div className="md:w-2/5 h-[300px] md:h-[400px] overflow-hidden relative">
                    <img
                      src={getImageUrl(event)}
                      alt={event.attributes.name}
                      className="w-full h-full object-cover transition-transform duration-300
                             group-hover:scale-105 absolute inset-0"
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default EventsPage;