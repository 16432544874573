import React, { useState, useEffect } from 'react';
import { CartProvider } from './context/CartContext';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import HomePage from './pages/HomePage/HomePage';
import AboutPage from './pages/AboutPage/AboutPage';
import BlogPage from './pages/BlogPage/BlogPage';
import BlogPost from './pages/BlogPost/BlogPost';
import GalleryPage from './pages/GalleryPage/GalleryPage';
import StoreComingSoon from './pages/StorePage/StoreComingSoon';
import CartCheckoutPage from './pages/CartCheckoutPage/CartCheckoutPage';
import ContactPage from './pages/ContactPage/ContactPage';
import EventsPage from './pages/EventsPage/EventsPage';
import ProductPage from './pages/ProductPage/ProductPage';
import SEOHead from './components/SEOHead';
import './index.css';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [isContentReady, setIsContentReady] = useState(false);

  useEffect(() => {
    // Preload key assets
    const preloadAssets = async () => {
      const assetsToPreload = [
        '/videos/hero-background.mp4',
        '/images/logo.png',
        // Add other critical images here
      ];

      const preloadPromises = assetsToPreload.map(asset => {
        return new Promise((resolve, reject) => {
          if (asset.endsWith('.mp4')) {
            const video = document.createElement('video');
            video.src = asset;
            video.onloadeddata = resolve;
            video.onerror = reject;
          } else {
            const img = new Image();
            img.src = asset;
            img.onload = resolve;
            img.onerror = reject;
          }
        });
      });

      try {
        await Promise.all(preloadPromises);
        setIsContentReady(true);
      } catch (error) {
        console.error('Error preloading assets:', error);
        setIsContentReady(true); // Continue anyway
      }
    };

    preloadAssets();
  }, []);

  const handleLoadingComplete = () => {
    setIsLoading(false);
    setTimeout(() => {
      setIsContentVisible(true);
    }, 100);
  };

  return (
    <HelmetProvider>
      {isLoading && <LoadingScreen onLoadingComplete={handleLoadingComplete} />}
      <CartProvider>
        <Router>
          <div className={`App app-content ${isContentVisible ? 'visible' : ''}`}>
            {/* Default SEO tags that will be overridden by individual pages */}
            <SEOHead 
              title="World Budo Arts - Authentic Martial Arts Training"
              description="Experience authentic martial arts training with World Budo Arts. Learn Kyokushin Karate, self-defense, and martial philosophy from Shihan Tom Callahan."
              path="/"
            />
            <Header />
            <main>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/blog" element={<BlogPage />} />
                <Route path="/blog/:slug" element={<BlogPost />} />
                <Route path="/gallery" element={<GalleryPage />} />
                <Route path="/store" element={<StoreComingSoon />} />
                <Route path="/product/:id" element={<ProductPage />} />
                <Route path="/cart" element={<CartCheckoutPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/events" element={<EventsPage />} />
              </Routes>
            </main>
            <Footer />
          </div>
        </Router>
      </CartProvider>
    </HelmetProvider>
  );
}

export default App;